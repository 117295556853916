import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import moment from 'moment';

import SEO from '../components/seo';
import Breadcrumbs from '../components/breadcrumbs';
import ImgGallery from '../components/img-gallery';
import ProductForm from '../components/product-form';

import styles from '../styles/product.module.scss';
import ShopCarousel from '../components/shop-carousel';

const prepareImgs = (product) => (
	product.images.map((x) => ({
		key: x.id,
		img: x.localFile.childImageSharp.main,
		thumb: x.localFile.childImageSharp.thumb,
		ogimage: x.localFile.childImageSharp.ogimage,
		src: x.originalSrc,
		alt: product.title
	}))
);

const Product = ({ pageContext, data }) => {
	const { site, product, moreProducts } = data;

	const hasMoreProducts = Boolean(moreProducts.edges.length);

	const vendor = data.vendor.edges.length ? data.vendor.edges[0].node : null;

	const route = product.vendor !== 'Roselium' ? `/our-partners/${vendor.handle}/` : '/about-us/';

	const [imgs] = useState(prepareImgs(product));

	return (
		<>
			<SEO
				title={product.title}
				description={pageContext.description}
				image={imgs.length ? imgs[0].ogimage.src : null}
				path={`/product/${product.handle}/`}
				jsonld={[
					{
						"@context": "https://schema.org/",
						"@type": "Product",
						"name": product.title,
						"image": imgs.map((x) => (site.siteMetadata.siteUrl + x.img.src)),
						"description": pageContext.description,
						"brand": {
							"@type": 'Organization',
							"name": product.vendor
						},
						"offers": {
							"@type": "Offer",
							"url": site.siteMetadata.siteUrl + `/product/${product.handle}/`,
							"priceCurrency": "USD",
							"price": product.variants[0].price,
							"priceValidUntil": moment().add(6, 'M').format('YYYY-MM-DD'),
							"itemCondition": "https://schema.org/NewCondition",
							"availability": "https://schema.org/InStock",
							"seller": {
								"@type": "Organization",
								"name": "Roselium"
							}
						}
					}
				]}
			/>
			<div className={styles.main}>
				<div className={styles.content}>
					<section className={styles.top}>
						<Breadcrumbs
							crumbs={[
								{
									name: 'Store',
									to: '/store/all/'
								},
								{
									name: product.vendor,
									to: route
								},
								{
									name: product.title
								}
							]}
						/>
					</section>
					<section className={styles.product}>
						<div className={styles.photos}>
							<ImgGallery imgs={imgs} />
							<hr />
						</div>
						<div className={styles.info}>
							<ProductForm
								product={product}
								vendor={vendor}
								route={route}
							/>
						</div>
					</section>
					{hasMoreProducts &&
						<hr />
					}
				</div>
				{hasMoreProducts &&
					<section className={styles.similar}>
						<div className={styles.content}>
							<h5>More Products By <br /><Link to={route}>{product.vendor}</Link></h5>
							<ShopCarousel
								size='sm'
								overlay='primary'
								products={moreProducts.edges}
							/>
						</div>
					</section>
				}
			</div>
		</>
	);
};

export const query = graphql`
    query($handle: String!, $vendor: String!) {
      site {
        siteMetadata {
          siteUrl
        }
      }

      product: shopifyProduct(handle: { eq: $handle }) {
        id
        title
        handle
        productType
        vendor
        descriptionHtml
        shopifyId
        options {
          id
          name
          values
        }
        variants {
          id
          title
          price
          availableForSale
          shopifyId
          selectedOptions {
            name
            value
          }
        }
        images {
          originalSrc
          id
          localFile {
            childImageSharp {
              main: fluid(quality: 90, maxWidth: 4096) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
              thumb: fixed(quality: 90, width: 60) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
              ogimage: fixed(quality: 90, width: 1200, height: 630, cropFocus: CENTER) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
      
      moreProducts: allShopifyProduct(
          filter: {
            vendor: { eq: $vendor }
            handle: { ne: $handle }
          }
          sort: {
            fields: createdAt
            order: DESC
          },
          limit: 24
        ) {
          edges {
            node {
              id
              title
              handle
              productType
              shopifyId
              images {
                id
                originalSrc
                localFile {
                  childImageSharp {
                    fluid(quality: 90, maxWidth: 520) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
              }
              variants {
                shopifyId
                price
              }
            }
          }
        }

        vendor: allVendorsJson (
            filter: {
                name: { eq: $vendor }
            }
        ) {
            edges {
                node {
                    handle
                    description
                }
            }
        }
    }
  `;

export default Product;