import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Button } from '@material-ui/core';
import { Link } from 'gatsby';

import ProductPrice from './product-price';
import VariantSelector from './variant-selector';
import Stepper from './stepper';
import ExpansionPanel from './expansion-panel';
import StoreContext from '../context/store-context';
import { FREE_SHIPPING_MIN } from '../constants/app';

import styles from '../styles/product.module.scss';

import shipping from '../images/icons/shipping.svg';

const prepareOptions = (product) => {
    const options = {};

    product.options.forEach(x => {
        options[x.name] = x.values[0];
    });

    return options;
};

const ProductForm = ({ product, vendor, route }) => {
    const [init, setInit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const [variant, setVariant] = useState(prepareOptions(product));
    const context = useContext(StoreContext);

    const hasVariants = product.variants.length > 1;
    const productVariant =
        context.client.product.helpers.variantForOptions(product, variant) ||
        variant;
    const [available, setAvailable] = useState(!init);

    const checkAvailability = () => {
        if (init) {
            setLoading(true);
        }

        context.client.product.fetch(product.shopifyId).then((product) => {
            const result = product.variants.filter(
                variant => variant.id === productVariant.shopifyId
            );

            if (result && result.length) {
                setAvailable(result[0].available);
            } else {
                setAvailable(false);
            }

            setLoading(false);
            setInit(true);
        })
    };

    useEffect(() => {
        checkAvailability();
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productVariant]);

    const onOptionChange = (event) => {
        const { target } = event;
        setVariant(prevState => ({
            ...prevState,
            [target.name]: target.value,
        }));
    };

    const onQuantityChange = (val) => {
        setQuantity(val);
    };

    const handleAddToCart = () => {
        context.addVariantToCart(
            product.shopifyId,
            productVariant.shopifyId,
            quantity
        );
    };

    const VariantSelectors = () => {
        if (!hasVariants) {
            return null;
        }

        return (
            <div className={styles.variants}>
                {product.options.map(option => (
                    <div
                        key={option.id}
                        className={styles.variant}
                    >
                        <VariantSelector
                            value={variant[option.name]}
                            option={option}
                            onChange={onOptionChange}
                            className={styles.selector}
                        />
                    </div>
                ))}
            </div>
        );
    };

    const Price = () => {
        if (loading) {
            return (
                <CircularProgress
                    color='inherit'
                    size={24}
                />
            );
        }

        if (!available) {
            return 'Unavailable';
        }

        return (
            <ProductPrice
                value={productVariant.price}
                className={styles.priceVal}
                showCompare={true}
                showPerk={true}
                showDecimal={true}
            />
        )
    };

    return (
        <>
            <small className={styles.type}>{product.productType}</small>
            <h1>{product.title}</h1>
            {product.vendor &&
                <small className={styles.vendor}>by <Link to={route}>{product.vendor}</Link></small>
            }
            <div className={styles.price}>
                <Price />
            </div>
            <VariantSelectors />
            <div className={styles.quantity}>
                <div className={styles.stepper}>
                    <label>Quantity:</label>
                    <Stepper
                        value={quantity}
                        min={1}
                        disabled={!available}
                        onChange={onQuantityChange}
                    />
                </div>
            </div>
            <Button
                variant='contained'
                color='primary'
                disabled={loading || !available}
                className={styles.addtocart}
                fullWidth={true}
                onClick={handleAddToCart}
            >
                Add To Cart
            </Button>
            <p
                className={styles.description}
                dangerouslySetInnerHTML={{ __html: product.descriptionHtml }}
            />
            <ul className={styles.perks}>
                <li>
                    <div className={styles.inner}>
                        <img src={shipping} alt='Free Shipping Icon' />
                        <small>Free US Shipping for Store Orders Over ${FREE_SHIPPING_MIN}</small>
                    </div>
                </li>
            </ul>
            {vendor &&
                <section className={styles.about}>
                    <ExpansionPanel
                        title={`About ${product.vendor}`}
                    >
                        <p className={styles.description}>{vendor.description}</p>
                    </ExpansionPanel>
                </section>
            }
        </>
    )
};

ProductForm.propTypes = {
    product: PropTypes.shape({
        descriptionHtml: PropTypes.string,
        handle: PropTypes.string,
        id: PropTypes.string,
        shopifyId: PropTypes.string,
        images: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                originalSrc: PropTypes.string,
            })
        ),
        options: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                name: PropTypes.string,
                values: PropTypes.arrayOf(PropTypes.string),
            })
        ),
        productType: PropTypes.string,
        title: PropTypes.string,
        vendor: PropTypes.string,
        variants: PropTypes.arrayOf(
            PropTypes.shape({
                availableForSale: PropTypes.bool,
                id: PropTypes.string,
                price: PropTypes.string,
                title: PropTypes.string,
                shopifyId: PropTypes.string,
                selectedOptions: PropTypes.arrayOf(
                    PropTypes.shape({
                        name: PropTypes.string,
                        value: PropTypes.string,
                    })
                ),
            })
        ),
    }),
    addVariantToCart: PropTypes.func,
};

export default ProductForm;
