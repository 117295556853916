import React from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { ChevronDown } from 'react-feather';

import styles from '../styles/expansion-panel.module.scss';

const CustomExpansionPanel = ({ id, title, children }) => (
    <ExpansionPanel
        square={true}
        classes={{
            root: styles.panel
        }}
    >
        <ExpansionPanelSummary
            expandIcon={<ChevronDown />}
            aria-controls={`${id}-content`}
            id={`${id}-header`}
            classes={{
                root: styles.summary,
                content: styles.content,
                expandIcon: styles.expand,
                expanded: styles.expanded
            }}
        >
            <h6>{title}</h6>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
            classes={{
                root: styles.details
            }}
        >
            {children}
        </ExpansionPanelDetails>
    </ExpansionPanel>
);

export default CustomExpansionPanel;